.helptext {
  text-align: left;
}

.charRemaining {
  float: right;
}

/* Solid border */
hr.solid {
  border-top: 1px solid #bbb;
}
