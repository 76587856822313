.nav {
  height: 40px;
}

.pagetitle {
  font-size: 1.3em !important;
}

.nav-link {
  height: 40px;
}

.nav-link {
  color: white !important;
  font-size: 1.5em !important;
}
